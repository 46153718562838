import React, { useState } from "react";
import { Card, DataTable, Text, ToggleButton } from "react-native-paper";
import { StyleSheet } from "react-native";
import { metersToFormattedUserUnits } from "./utils/distance";
import { formatDuration, formatDurationToTime } from "./utils/time";

const TotalsDataTable = ({ totals }) => {
  if (!totals || totals.total === null) {
    // Handle the case when totals or totals.total is null
    return (
      <DataTable>
        <DataTable.Header>
          <DataTable.Title style={styles.centeredCell}>Planned</DataTable.Title>
          <DataTable.Title style={styles.centeredCell}> </DataTable.Title>
          <DataTable.Title style={styles.centeredCell}>
            Completed
          </DataTable.Title>
        </DataTable.Header>

        <DataTable.Row>
          <DataTable.Cell style={styles.centeredCell} numeric>
            -
          </DataTable.Cell>
          <DataTable.Cell style={styles.centeredCell}>Workouts</DataTable.Cell>
          <DataTable.Cell style={styles.centeredCell} numeric>
            -
          </DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row>
          <DataTable.Cell style={styles.centeredCell} numeric>
            -
          </DataTable.Cell>
          <DataTable.Cell style={styles.centeredCell}>Duration</DataTable.Cell>
          <DataTable.Cell style={styles.centeredCell} numeric>
            -
          </DataTable.Cell>
        </DataTable.Row>

        <DataTable.Row>
          <DataTable.Cell style={styles.centeredCell} numeric>
            -
          </DataTable.Cell>
          <DataTable.Cell style={styles.centeredCell}>Distance</DataTable.Cell>
          <DataTable.Cell style={styles.centeredCell} numeric>
            -
          </DataTable.Cell>
        </DataTable.Row>
      </DataTable>
    );
  }

  // If totals and totals.total are not null, render the table with data
  return (
    <DataTable>
      <DataTable.Header>
        <DataTable.Title style={styles.centeredCell}>Planned</DataTable.Title>
        <DataTable.Title style={styles.centeredCell}></DataTable.Title>
        <DataTable.Title style={styles.centeredCell}>Completed</DataTable.Title>
      </DataTable.Header>

      <DataTable.Row>
        <DataTable.Cell style={styles.centeredCell} numeric>
          {totals.total_time_planned !== null
            ? totals.total_count_planned
            : "-"}
        </DataTable.Cell>
        <DataTable.Cell style={styles.centeredCell}>Workouts</DataTable.Cell>
        <DataTable.Cell style={styles.centeredCell} numeric>
          {totals.total_time !== null ? totals.total_count : "-"}
        </DataTable.Cell>
      </DataTable.Row>

      <DataTable.Row>
        <DataTable.Cell style={styles.centeredCell} numeric>
          {totals.total_time_planned !== 0
            ? formatDuration(totals.total_time_planned)
            : "-"}
        </DataTable.Cell>
        <DataTable.Cell style={styles.centeredCell}>Duration</DataTable.Cell>
        <DataTable.Cell style={styles.centeredCell} numeric>
          {formatDuration(totals.total_time)}
        </DataTable.Cell>
      </DataTable.Row>

      <DataTable.Row>
        <DataTable.Cell style={styles.centeredCell} numeric>
          {totals.total_distance_planned !== 0
            ? metersToFormattedUserUnits(totals.total_distance_planned)
            : "-"}
        </DataTable.Cell>
        <DataTable.Cell style={styles.centeredCell}>Distance</DataTable.Cell>
        <DataTable.Cell style={styles.centeredCell} numeric>
          {metersToFormattedUserUnits(totals.total_distance)}
        </DataTable.Cell>
      </DataTable.Row>
    </DataTable>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    padding: 16,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  type: {
    fontSize: 16,
    marginBottom: 8,
  },
  time: {
    fontSize: 16,
    marginBottom: 8,
  },
  startedAt: {
    fontSize: 16,
    marginBottom: 8,
  },
  completed: {
    fontSize: 16,
    marginBottom: 8,
  },
  centeredCell: {
    justifyContent: "center",
    alignItems: "center",
  },
  toggleButtonRow: {
    // justifyContent: "center",
    // marginTop: 10,
  },

  toggleButton: {
    // width: 100,
  },

  selectedToggleButton: {
    // width: 100,
    // backgroundColor: "blue", // Customize the selected button's background color
  },
});

export default TotalsDataTable;
