import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  Portal,
  Dialog,
  Button,
  TextInput,
  RadioButton,
  Text,
} from "react-native-paper";
import { LibraryWorkoutData } from "../../types/libraryWorkout";
import DatePickerInput from "../input/DatePickerInput";
import { DayOfWeekMenuSelector } from "../input/DayOfWeekSelector";
import DateRangePickerInput from "../input/DateRangePickerInput";
import {
  getCurrentViewUserId,
  getCurrentViewUserProfile,
} from "../../provider/CalendarProvider/formatSchedule";
import { useCalendar } from "../../provider/CalendarProvider";
import { CalendarActionTypes } from "../provider/CalendarProvider/types";
import { getLocalStartOfDayForDate } from "../utils/dateUtils";
import { createActivity } from "../../api/activities";
import { isCoach } from "../utils/coaches";
import {
  getDayOfWeekTodayLocal,
  getDatesInRange,
  getDateWeeksFromNow,
} from "../utils/dateUtils";
import { DateRange } from "../../types/calendar";
import CoachViewSelectorChip from "../Coaches/CoachViewSelectorChip";

interface ScheduleWorkoutDialogProps {
  visible: boolean;
  hideDialog: () => void;
  item: LibraryWorkoutData;
}

const ScheduleWorkoutDialog = ({
  visible,
  hideDialog,
  item,
}: ScheduleWorkoutDialogProps) => {
  const { state, dispatch } = useCalendar();
  const [coachWithAthletesView, setCoachWithAthletesView] = useState(false);
  const [workoutDate, setWorkoutDate] = useState<Date>(new Date());
  const [workoutDateRange, setWorkoutDateRange] = useState<DateRange>({
    startDate: undefined,
    endDate: undefined,
  });
  const [scheduleType, setScheduleType] = React.useState("once");
  const [selectedDays, setSelectedDays] = useState([getDayOfWeekTodayLocal()]); // default to today

  // prevent scheduling more than 12 weeks in advance or in the past
  const validRange: DateRange = {
    startDate: new Date(),
    endDate: getDateWeeksFromNow(12),
  };

  // single date when not using recurrence
  const handleDateChange = (date: Date) => {
    setWorkoutDate(date);
  };
  // date range when using recurrence
  const handleDateRangeChange = (dateRange: DateRange) => {
    setWorkoutDateRange(dateRange);
    console.log("Date range:", dateRange);
    console.log(getDatesInRange(dateRange, selectedDays));
  };

  const handleSchedule = async () => {
    try {
      let scheduledDates: Date[] = [];
      if (scheduleType === "recurrence") {
        scheduledDates = getDatesInRange(workoutDateRange, selectedDays);
      } else {
        scheduledDates = [workoutDate];
      }
      const user_id = getCurrentViewUserId(state); // (await supabase.auth.getSession()).data.session?.user.id;

      for (const date of scheduledDates) {
        const utcOffset = new Date().getTimezoneOffset() * -60;
        const workoutDateStr = getLocalStartOfDayForDate(date);
        const startedAtUtc = new Date(date);
        startedAtUtc.setSeconds(startedAtUtc.getSeconds() - utcOffset);

        const activity = {
          ...item,
          user_id,
          workout_date: workoutDateStr,
          started_at_local: workoutDateStr,
          utc_offset: utcOffset,
          started_at: date.toISOString(),
          is_planned: true,
          is_completed: false,
        };
        delete activity.workout_library_category_id;
        delete activity.id;

        // Create new activity
        const { data, error } = await createActivity(activity);

        if (error) {
          throw error;
        }
      }
      alert("Workout scheduled successfully!");
      // set state to fetch activities again
      dispatch({
        type: "FETCH_ACTIVITIES",
      });

      hideDialog();
    } catch (error) {
      console.error("Error saving activity:", error);
    }
  };

  useEffect(() => {
    if (
      state.profile !== null &&
      isCoach(state.profile) &&
      state.athletes.length > 0
    ) {
      setCoachWithAthletesView(true);
    } else {
      setCoachWithAthletesView(false);
    }
  }, []);

  return (
    <Portal>
      <Dialog visible={visible} onDismiss={hideDialog}>
        <Dialog.Title>Schedule Workout</Dialog.Title>
        <Dialog.Content>
          {coachWithAthletesView && (
            <CoachViewSelectorChip />
            // <Appbar.Action
            //   icon="account-filter"
            //   onPress={() => {
            //     /* Handle action press */
            //   }}
            // />
          )}
          <View>
            <RadioButton.Group
              onValueChange={(value) => setScheduleType(value)}
              value={scheduleType}
            >
              <RadioButton.Item label="Once" value="once" />
              <RadioButton.Item label="Recurrence" value="recurrence" />
            </RadioButton.Group>
          </View>
          {scheduleType === "once" ? (
            <DatePickerInput
              label="Date"
              value={workoutDate}
              onChange={(d: Date) => handleDateChange(d)}
              inputMode="start"
              style={styles.input}
            />
          ) : (
            <View>
              <View style={styles.dayOfWeekContainer}>
                <DateRangePickerInput
                  setDateRange={handleDateRangeChange}
                  validRange={validRange}
                />
                <DayOfWeekMenuSelector
                  selectedDays={selectedDays}
                  setSelectedDays={setSelectedDays}
                />
              </View>
            </View>
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Button onPress={hideDialog}>Cancel</Button>
          <Button onPress={handleSchedule}>Schedule</Button>
        </Dialog.Actions>
      </Dialog>
    </Portal>
  );
};

export default ScheduleWorkoutDialog;

const styles = StyleSheet.create({
  container: {
    paddingTop: 8,
    paddingHorizontal: 16,
  },
  input: {},
  dayOfWeekContainer: {},
});
