import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { ToggleButton } from "react-native-paper";

interface WeekDisplayProps {
  selectedWeekStart: Date;
}

const WeekDisplay: React.FC<WeekDisplayProps> = ({ selectedWeekStart }) => {
  const isThisWeek = (date: Date) => {
    const now = new Date();
    const startOfWeek = new Date(date);
    const endOfWeek = new Date(date);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    return now >= startOfWeek && now <= endOfWeek;
  };

  const formattedWeekStart = selectedWeekStart.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const weekEnd = new Date(selectedWeekStart);
  weekEnd.setDate(weekEnd.getDate() + 6);
  const formattedWeekEndString = weekEnd.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return (
    <View style={styles.dateRow}>
      <Text style={styles.dateText}>
        {isThisWeek(selectedWeekStart)
          ? "This Week"
          : `${formattedWeekStart} - ${formattedWeekEndString}`}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  dateRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  dateText: {
    marginHorizontal: 8, // Adjust the spacing as needed
  },
});

export default WeekDisplay;
