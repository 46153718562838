// activityService.ts
import { supabase } from "../initSupabase";
import {
  isSameWeek,
  startOfWeek,
  endOfWeek,
  isMonday,
  subDays,
  addDays,
} from "date-fns";
import { getPagination } from "./utils";
import { ActivityTypeValue, ActivityData } from "../types/activity";

interface QueryOptions {
  user_id?: string;
  page?: number;
  before?: Date;
  after?: Date;
  pageSize?: number;
}

export async function fetchActivities({
  query: { user_id, page = 0, before, after, pageSize = 20 } = {},
}: { query?: QueryOptions } = {}) {
  try {
    // Extract the page from the query or default to 1
    // use pagination util to calculate the from and to values
    const { from, to } = getPagination(page, pageSize);
    //
    let supabaseQuery = supabase
      .from("activities")
      .select("*", { count: "exact" });
    // Create a filter object based on the provided before and after values
    if (before) {
      supabaseQuery = supabaseQuery.lt("started_at", before.toISOString());
    }
    if (after) {
      supabaseQuery = supabaseQuery.gte("started_at", after.toISOString());
    }
    if (user_id) {
      supabaseQuery = supabaseQuery.eq("user_id", user_id);
    }
    supabaseQuery = supabaseQuery
      .range(from, to)
      .order("started_at", { ascending: false });
    // Fetch activities and return the raw data
    const { data, count, error } = await supabaseQuery;

    if (error) {
      throw error;
    }

    return {
      data: data,
      count: count,
      page: +page,
    }; // Return the raw data
  } catch (error) {
    console.error("Error fetching activities:", error);
    throw error;
  }
}

export async function fetchWorkoutsByTypeAndDate(
  date: string | null,
  type: ActivityTypeValue | null
) {
  try {
    const { data, error } = await supabase
      .from("activities")
      .select("*")
      .eq("type", type)
      .eq("workout_date", date);

    if (error) {
      throw error;
    }

    return data;
  } catch (error) {
    console.error("Error fetching workouts by type and date:", error);
    throw error;
  }
}

export async function fetchActivitiesInRange(
  user_id?: string,
  date: Date = new Date(),
  span: number = 90,
  pageSize: number = 20
) {
  try {
    const results = [];

    // Calculate the date range (plus and minus 90 days)
    const ninetyDaysAgo = subDays(date, span);
    const ninetyDaysLater = addDays(date, span);

    // Fetch activities for the first page
    let currentPage = 0;
    let hasNextPage = true;

    while (hasNextPage) {
      // Fetch activities using the fetchActivities function
      const result = await fetchActivities({
        query: {
          user_id, // Include user_id in the query if provided
          page: currentPage,
          before: ninetyDaysLater,
          after: ninetyDaysAgo,
          pageSize,
        },
      });

      // Add the current page's data to the results array
      results.push(result.data);

      // Check if there are more pages
      hasNextPage =
        result.count !== null &&
        result.page < Math.ceil(result.count / pageSize) - 1;
      currentPage++;

      // You may want to add a limit to avoid an infinite loop in case of unexpected issues
      if (currentPage > 100) {
        console.warn("Exceeded maximum page limit. Stopping pagination.");
        break;
      }
    }

    // Concatenate the data from all pages into a single array
    const allData = results.flat();

    return {
      data: allData,
      count: allData.length,
    };
  } catch (error) {
    console.error("Error fetching activities in range:", error);
    throw error;
  }
}

export async function fetchActivitiesInDateRange(
  user_id?: string,
  before: Date = new Date(),
  after: Date = subDays(new Date(), 90),
  pageSize: number = 20
) {
  try {
    const results = [];

    // Fetch activities for the first page
    let currentPage = 0;
    let hasNextPage = true;

    while (hasNextPage) {
      // Fetch activities using the fetchActivities function
      const result = await fetchActivities({
        query: {
          user_id, // Include user_id in the query if provided
          page: currentPage,
          before: before,
          after: after,
          pageSize,
        },
      });

      // Add the current page's data to the results array
      results.push(result.data);

      // Check if there are more pages
      hasNextPage =
        result.count !== null &&
        result.page < Math.ceil(result.count / pageSize) - 1;
      currentPage++;

      // You may want to add a limit to avoid an infinite loop in case of unexpected issues
      if (currentPage > 100) {
        console.warn("Exceeded maximum page limit. Stopping pagination.");
        break;
      }
    }

    // Concatenate the data from all pages into a single array
    const allData = results.flat();

    return {
      data: allData,
      count: allData.length,
    };
  } catch (error) {
    console.error("Error fetching activities in range:", error);
    throw error;
  }
}

export async function fetchNextActivity(user_id?: string): Promise<any | null> {
  try {
    const currentDate = new Date();
    const today = new Date(currentDate.setHours(0, 0, 0, 0));

    // Fetch the first activity from today onwards where is_completed is false
    let query = supabase
      .from("activities")
      .select("*")
      .eq("is_completed", false)
      .gte("started_at", today.toISOString())
      .order("started_at", { ascending: true })
      .limit(1);

    if (user_id) {
      query = query.eq("user_id", user_id);
    }

    const { data, error } = await query;

    if (error) {
      throw error;
    }

    // Return the first activity or null if no matching activity is found
    return data.length > 0 ? data[0] : null;
  } catch (error) {
    console.error("Error fetching next activity:", error);
    throw error;
  }
}

// Fetch activity by id
export async function fetchActivityById(id: string) {
  try {
    const { data, error } = await supabase
      .from("activities")
      .select("*")
      .eq("id", id)
      .single();

    if (error) {
      throw error;
    }

    // Return the latest completed activity or null if none found
    return data;
  } catch (error) {
    console.error("Error fetching activity by id:", error);
    throw error;
  }
}

// Fetch the most recent completed activity
export async function fetchLatestCompletedActivity() {
  try {
    const { data, error } = await supabase
      .from("activities")
      .select("*")
      .eq("is_completed", true)
      .order("started_at", { ascending: false })
      .limit(1);

    if (error) {
      throw error;
    }

    // Return the latest completed activity or null if none found
    return data.length > 0 ? data[0] : null;
  } catch (error) {
    console.error("Error fetching latest completed activity:", error);
    throw error;
  }
}

// Insert new activity
export async function createActivity(activity: ActivityData) {
  try {
    const { data, error } = await supabase
      .from("activities")
      .insert([activity])
      .single();

    if (error) {
      throw error;
    }

    return { data, error };
  } catch (error) {
    console.error("Error creating activity:", error);
    throw error;
  }
}
