import React from "react";
import { View, StyleSheet, Platform } from "react-native";
import { Text } from "react-native-paper";
import Victory from "./victory/victory";
import {
  dateToWeekdayAbbreviation,
  isFirstWeekOfMonth,
} from "../utils/dateUtils";
import { activityStatuses } from "../../constants/activityTypes";
import { ActivityTotals, TotalTypeKey } from "../../types/activity";
import { format } from "date-fns";

const VictoryBar = Victory.VictoryBar;
const VictoryChart = Victory.VictoryChart;
const VictoryTheme = Victory.VictoryTheme;
const VictoryAxis = Victory.VictoryAxis;
const VictoryLabel = Victory.VictoryLabel;
const VictoryStack = Victory.VictoryStack;
const VictoryTooltip = Victory.VictoryTooltip;
const VictoryVoronoiContainer = Victory.VictoryVoronoiContainer;
const VictoryLegend = Victory.VictoryLegend;

interface DataPoint extends ActivityTotals {
  category: string;
  value: number;
}

interface BarChartProps {
  data: DataPoint[];
  labelKey: string;
  valueKey: number;
  totalType: TotalTypeKey;
  onBarClick?: (data: DataPoint) => void;
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
});

const barRatio = 0.8;

const formatDuration = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  return `${hours}:${minutes.toString().padStart(2, "0")}`;
};

const formatTooltipLabel = (datum: any, totalType: string) => {
  if (totalType === "distance") {
    return `${datum._y.toFixed(1)} km`;
  } else if (totalType === "duration") {
    return formatDuration(datum._y); // Assuming formatDuration is a function that formats duration
  } else {
    return `${datum._y}`; // Default case for count or other types
  }
};

const BarChart: React.FC<BarChartProps> = ({
  data,
  labelKey = "label",
  totalType = "count",
  onBarClick,
}) => {
  const handleBarClick = (event: any, data: any) => {
    if (onBarClick) {
      // only pass the datum (selected datapoint) to the callback
      onBarClick(data.datum);
    }
  };

  // Define tick format and tick count based on totalType
  let tickFormat = (t: number) => (t === Math.floor(t) ? t.toString() : "");

  // if totalType is distance, convert data in m to km
  // (total_distance, total_distance_completed, total_distance_missed, total_distance_planned, total_distance_planned_incomplete)
  if (totalType === "distance") {
    data = data.map((d) => ({
      ...d,
      total_distance: d.total_distance / 1000,
      total_distance_missed: d.total_distance_missed / 1000,
      total_distance_planned: d.total_distance_planned / 1000,
      total_distance_planned_incomplete:
        d.total_distance_planned_incomplete / 1000,
    }));
  }

  if (totalType === "distance") {
    tickFormat = (t: number) => `${t} km`;
    // tickCount = 5;
  } else if (totalType === "duration") {
    tickFormat = (t: number) => formatDuration(t);
    // tickCount = 4;
  }

  return (
    <View style={styles.container}>
      <VictoryChart
        width={400}
        height={300}
        theme={VictoryTheme.material}
        domainPadding={{ x: 15 }}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryLegend
          x={60}
          y={280}
          orientation="horizontal"
          gutter={20}
          style={{ title: { fontSize: 20 } }}
          data={[
            {
              name: activityStatuses.completed.label,
              symbol: { fill: activityStatuses.completed.color },
            },
            {
              name: activityStatuses.planned.label,
              symbol: { fill: activityStatuses.planned.color },
            },
            { name: "Missed", symbol: { fill: activityStatuses.missed.color } },
          ]}
        />
        <VictoryAxis
          tickFormat={(x) =>
            isFirstWeekOfMonth(x) ? format(new Date(x), "MMM-yy") : ""
          }
          style={{
            ticks: { size: 0 }, // Set the tick size to 0 to hide tick lines
          }}
        />
        <VictoryAxis
          dependentAxis
          //tickCount={tickCount} // Adjust the number of ticks as needed
          minDomain={{ y: 0 }}
          tickFormat={tickFormat}
        />
        <VictoryStack>
          <VictoryBar
            data={data}
            x={labelKey}
            y={activityStatuses.completed.totalKey[totalType]} // totalType completed
            barRatio={barRatio}
            labelComponent={<VictoryTooltip />}
            labels={({ datum }) => formatTooltipLabel(datum, totalType)}
            style={{
              data: {
                fill: activityStatuses.completed.color, // Set the completed color here
              },
            }}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: handleBarClick,
                },
              },
            ]}
          />
          <VictoryBar
            data={data}
            x={labelKey}
            y={activityStatuses.missed.totalKey[totalType]} // totalType missed
            barRatio={barRatio}
            labelComponent={<VictoryTooltip />}
            labels={({ datum }) => formatTooltipLabel(datum, totalType)}
            style={{
              data: {
                fill: activityStatuses.missed.color, // Set the missed color here
              },
            }}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: handleBarClick,
                },
              },
            ]}
          />
          <VictoryBar
            data={data}
            x={labelKey}
            y={activityStatuses.planned.totalKey[totalType]} // Replace with totalType planned
            barRatio={barRatio}
            labelComponent={<VictoryTooltip />}
            labels={({ datum }) => formatTooltipLabel(datum, totalType)}
            style={{
              data: {
                fill: activityStatuses.planned.color, // Set the color here
              },
            }}
            events={[
              {
                target: "data",
                eventHandlers: {
                  onClick: handleBarClick,
                },
              },
            ]}
          />
        </VictoryStack>
      </VictoryChart>
    </View>
  );
};

export default BarChart;
