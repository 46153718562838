import {
  emptyLibraryWorkoutData,
  LibraryWorkoutData,
} from "../../types/libraryWorkout";

// function to take a workout/activity-like object, delete non-library workout fields, and return a LibraryWorkoutData object
export const convertToLibraryWorkoutData = (
  workout: any,
  isEditMode: boolean = false
): LibraryWorkoutData => {
  const libraryWorkoutData: LibraryWorkoutData = {
    type: workout.type ?? null,
    title: workout.title ?? null,
    description: workout.description ?? null,
    data: workout.data ?? null,
    total_distance_planned: workout.total_distance_planned ?? null,
    total_time_planned: workout.total_time_planned ?? null,
    average_speed_planned: workout.average_speed_planned ?? null,
    workout_library_category_id: workout.workout_library_category_id ?? null,
  };
  if (isEditMode) {
    libraryWorkoutData.id = workout.id ?? null;
  }
  return libraryWorkoutData;
};
