// src/components/DaySelector.tsx
import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import {
  SegmentedButtons,
  Checkbox,
  Text,
  List,
  Menu,
} from "react-native-paper";
import { DaysOfWeek } from "../../types/calendar";

interface DayOfWeekSelectorProps {
  selectedDays: string[];
  setSelectedDays: (days: string[]) => void;
}

type Day = {
  label: string;
  value: DaysOfWeek;
  labelFull: string;
};

const days: Day[] = [
  { label: "Mon", value: DaysOfWeek.Monday, labelFull: "Monday" },
  { label: "Tue", value: DaysOfWeek.Tuesday, labelFull: "Tuesday" },
  { label: "Wed", value: DaysOfWeek.Wednesday, labelFull: "Wednesday" },
  { label: "Thu", value: DaysOfWeek.Thursday, labelFull: "Thursday" },
  { label: "Fri", value: DaysOfWeek.Friday, labelFull: "Friday" },
  { label: "Sat", value: DaysOfWeek.Saturday, labelFull: "Saturday" },
  { label: "Sun", value: DaysOfWeek.Sunday, labelFull: "Sunday" },
];

export const DayOfWeekHorizontalSelector = ({
  selectedDays,
  setSelectedDays,
}: DayOfWeekSelectorProps) => {
  return (
    <View style={styles.horizontalContainer}>
      <SegmentedButtons
        value={selectedDays}
        onValueChange={setSelectedDays}
        buttons={days}
        multiSelect
      />
    </View>
  );
};

export const DayOfWeekCheckboxSelector = ({
  selectedDays,
  setSelectedDays,
}: DayOfWeekSelectorProps) => {
  const handleCheckboxChange = (day: DaysOfWeek) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  return (
    <View style={styles.container}>
      {days.map((day) => (
        // <View key={day.value} style={styles.checkboxContainer}>
        <Checkbox.Item
          status={selectedDays.includes(day.value) ? "checked" : "unchecked"}
          onPress={() => handleCheckboxChange(day.value)}
          label={day.label}
        />

        // </View>
      ))}
    </View>
  );
};

export const DayOfWeekMenuSelector = ({
  selectedDays,
  setSelectedDays,
}: DayOfWeekSelectorProps) => {
  const [visible, setVisible] = useState(false);

  const openMenu = () => setVisible(true);
  const closeMenu = () => setVisible(false);

  const handleCheckboxChange = (day: DaysOfWeek) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    } else {
      setSelectedDays([...selectedDays, day]);
    }
    console.log("selectedDays", selectedDays);
  };

  const selectedLabels =
    selectedDays.length === days.length
      ? "Every day"
      : days
          .filter((day) => selectedDays.includes(day.value))
          .map((day) => day.labelFull)
          .join(", ");

  return (
    <View>
      <Menu
        visible={visible}
        onDismiss={closeMenu}
        anchor={
          <List.Item
            title={selectedLabels || "Select days"}
            description="Repeat on"
            onPress={openMenu}
            left={(props) => <List.Icon {...props} icon="repeat" />}
            right={(props) => <List.Icon {...props} icon="chevron-right" />}
          />
        }
      >
        {days.map((day) => (
          <Menu.Item
            key={day.value}
            onPress={() => handleCheckboxChange(day.value)}
            title={day.labelFull}
            trailingIcon={(props) => (
              <Checkbox
                {...props}
                status={
                  selectedDays.includes(day.value) ? "checked" : "unchecked"
                }
              />
            )}
          />
        ))}
      </Menu>
    </View>
  );
};

const styles = StyleSheet.create({
  horizontalContainer: {
    marginVertical: 10,
  },
  container: {
    // flexDirection: "row",
    // flexWrap: "wrap",
  },
  checkboxContainer: {
    // flexDirection: "row",
    // alignItems: "center",
    // marginRight: 10,
  },
});
