import * as React from "react";
import { View, StyleSheet } from "react-native";
import { List, Avatar, Badge, Text } from "react-native-paper";
import ActivityRecordingCard from "../ActivityRecording/ActivityRecordingCard";

const ActivityDetailsList = ({ activityRecordings }) => (
  <List.AccordionGroup>
    <List.Accordion title="Analysis" id="1">
      <List.Item title="Charts" />
      <List.Item title="Time in Zones" />
      <List.Item title="Best Efforts" />
    </List.Accordion>
    <List.Accordion title="Splits" id="2">
      <List.Item title="Splits" />
    </List.Accordion>
    <List.Accordion title="Map" id="3">
      <List.Item title="Item 2" />
    </List.Accordion>
    <List.Accordion
      title="Recorded Data"
      id="4"
      left={(props) =>
        activityRecordings.length > 0 ? (
          <View style={styles.badgeContainer}>
            <Badge>{activityRecordings.length.toString()}</Badge>
          </View>
        ) : null
      }
    >
      {activityRecordings.length > 0 ? (
        activityRecordings.map((activityRecording, index) => (
          <ActivityRecordingCard
            key={index}
            activityRecording={activityRecording}
          />
        ))
      ) : (
        <Text>None.</Text>
      )}
    </List.Accordion>
    <List.Accordion title="Notes" id="5">
      <List.Item title="Notes" />
    </List.Accordion>
  </List.AccordionGroup>
);

const styles = StyleSheet.create({
  badgeContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
  },
});

export default ActivityDetailsList;
