import {
  CoachedAthleteProfile,
  CoachProfile,
  UserProfile,
} from "../../types/user";
import { Database } from "../../types/supabase";
import { WorkoutLibrary } from "../../types/libraryWorkout";

export type CoachViewType = "SELF" | "ATHLETE" | "SUMMARY";

export enum CoachViewTypes {
  SELF = "SELF",
  ATHLETE = "ATHLETE",
  SUMMARY = "SUMMARY",
}

interface AthleteView {
  type: "ATHLETE";
  id: string;
}

interface SelfView {
  type: "SELF";
}

interface CoachesSummaryView {
  type: "SUMMARY";
}

export type CoachView = SelfView | AthleteView | CoachesSummaryView;

// Define action structure
interface SetCoachViewAction {
  type: CalendarActionTypes.SET_COACH_VIEW;
  payload: CoachView;
}

// calendarReducer.ts
export interface CalendarState {
  showCalendar: boolean;
  loading: boolean;
  activities: any[];
  athletes: CoachedAthleteProfile[];
  fetchAthletesRequired: boolean;
  coaches: CoachProfile[];
  fetchCoachesRequired: boolean;
  schedule: any[];
  workoutLibrary: any[];
  focusDate: string;
  fetchRequired: boolean;
  fetchProfileRequired: boolean;
  fetchWorkoutLibraryRequired: boolean;
  profile: UserProfile | null;
  error: any;
  coachView: CoachView;
}

export enum CalendarActionTypes {
  TOGGLE_CALENDAR = "TOGGLE_CALENDAR",
  SET_LOADING = "SET_LOADING",
  SET_LOADED = "SET_LOADED",
  SET_FOCUS_DATE = "SET_FOCUS_DATE",
  FETCH_ACTIVITIES = "FETCH_ACTIVITIES",
  EXTEND_ACTIVITIES = "EXTEND_ACTIVITIES",
  FETCH_ACTIVITIES_COMPLETE = "FETCH_ACTIVITIES_COMPLETE",
  SET_SCHEDULE = "SET_SCHEDULE",
  SET_ACTIVITIES = "SET_ACTIVITIES",
  SET_PROFILE = "SET_PROFILE",
  FETCH_PROFILE = "FETCH_PROFILE",
  FETCH_PROFILE_COMPLETE = "FETCH_PROFILE_COMPLETE",
  FETCH_ATHLETES = "FETCH_ATHLETES",
  FETCH_ATHLETES_SUCCESS = "FETCH_ATHLETES_SUCCESS",
  FETCH_ATHLETES_FAILURE = "FETCH_ATHLETES_FAILURE",
  FETCH_COACHES = "FETCH_COACHES",
  FETCH_COACHES_SUCCESS = "FETCH_COACHES_SUCCESS",
  FETCH_COACHES_FAILURE = "FETCH_COACHES_FAILURE",
  FETCH_WORKOUT_LIBRARY = "FETCH_WORKOUT_LIBRARY",
  FETCH_WORKOUT_LIBRARY_SUCCESS = "FETCH_WORKOUT_LIBRARY_SUCCESS",
  FETCH_WORKOUT_LIBRARY_FAILURE = "FETCH_WORKOUT_LIBRARY_FAILURE",
  SET_COACH_VIEW = "SET_COACH_VIEW",
}

export type CalendarAction =
  | { type: CalendarActionTypes.TOGGLE_CALENDAR; showCalendar: boolean }
  | { type: CalendarActionTypes.SET_LOADING }
  | { type: CalendarActionTypes.SET_LOADED }
  | {
      date: string;
      type: CalendarActionTypes.SET_FOCUS_DATE;
      focusDate: string;
    }
  | { type: CalendarActionTypes.FETCH_ACTIVITIES }
  | { type: CalendarActionTypes.FETCH_ACTIVITIES_COMPLETE }
  | {
      activities: any[];
      type: CalendarActionTypes.SET_SCHEDULE;
      schedule: any[];
    }
  | {
      type: CalendarActionTypes.SET_ACTIVITIES;
      activities: any[];
      schedule: any[];
    }
  | {
      type: CalendarActionTypes.SET_PROFILE;
      profile: Database["public"]["Tables"]["profiles"]["Row"];
    }
  | { type: CalendarActionTypes.FETCH_PROFILE }
  | { type: CalendarActionTypes.FETCH_PROFILE_COMPLETE }
  | { type: CalendarActionTypes.FETCH_ATHLETES }
  | {
      type: CalendarActionTypes.FETCH_ATHLETES_SUCCESS;
      athletes: CoachedAthleteProfile[];
    }
  | {
      type: CalendarActionTypes.FETCH_ATHLETES_FAILURE;
      error: any;
    }
  | { type: CalendarActionTypes.FETCH_COACHES }
  | {
      type: CalendarActionTypes.FETCH_COACHES_SUCCESS;
      coaches: CoachProfile[];
    }
  | {
      type: CalendarActionTypes.FETCH_COACHES_FAILURE;
      error: any;
    }
  | SetCoachViewAction
  | { type: CalendarActionTypes.FETCH_WORKOUT_LIBRARY }
  | {
      type: CalendarActionTypes.FETCH_WORKOUT_LIBRARY_SUCCESS;
      workoutLibrary: WorkoutLibrary;
    }
  | {
      type: CalendarActionTypes.FETCH_WORKOUT_LIBRARY_FAILURE;
      error: any;
    };
