import React, { useEffect, useState } from "react";
import { Card, DataTable, Text, ToggleButton } from "react-native-paper";
import { StyleSheet, View } from "react-native";
import TotalsDataTable from "../TotalsDataTable";
import { types as activityTypes } from "../../constants/activityTypes";
import { WeeklyActivityTotalsPerActivityCollection } from "../../types/activity";
import { fetchActivitiesInDateRange } from "../../api/activities";
import BarChart from "../charts/LongTermTrainingSummaryBarChart";
import {
  convertDailyTotalsObjectToArray,
  calculateLongTermWeeklyTotalsPerActivity,
  calculateWeeklyTotalsPerActivityForDateRange,
  getOldestAndNewestDates,
} from "../utils/activityUtils";
import TotalTypeSelect from "../TotalTypeSelect";
import {
  formatDateWithClientTimeZone,
  getEndOfWeekForDate,
  getStartOfNextWeekForDate,
  getStartOfWeekForDate,
} from "../utils/dateUtils";
import { useCalendar } from "../../provider/CalendarProvider";
import { getCurrentViewUserId } from "../../provider/CalendarProvider/formatSchedule";
import { TotalTypeKey } from "../../types/activity";
import Loading from "../../screens/utils/Loading";
import DateRangeSelector from "./DateRangeSelector";
import WeekDisplay from "./WeekDisplay";
import ActivityTypeToggle from "./ActivityTypeToggle";

const LongTermTrainingSummaryCard = () => {
  const { state, dispatch } = useCalendar();
  const [selectedKey, setSelectedKey] = useState<string>("total");
  const [selectedBar, setSelectedBar] = useState(null);
  const [chartLoading, setChartLoading] = useState<boolean>(true);
  const {
    oldestDate: initOldestDateFetched,
    newestDate: initNewestDateFetched,
  } = getOldestAndNewestDates(state.activities);
  const [oldestDateFetched, setOldestDateFetched] = useState<Date | null>(
    initOldestDateFetched
  );
  const [newestDateFetched, setNewestDateFetched] = useState<Date | null>(
    initNewestDateFetched
  );
  const [selectedTotalType, setSelectedTotalType] =
    useState<TotalTypeKey>("duration");
  const [selectedWeekStart, setSelectedWeekStart] = useState(
    getStartOfWeekForDate(new Date()) // Initialize with the start of the current week
  );
  // get end of next week and start of week 17 weeks prior
  const initChartRange = 17;
  const endOfNextWeek: Date = getEndOfWeekForDate(
    getStartOfNextWeekForDate(new Date())
  );
  const startOfWeek17WeeksAgo: Date = getStartOfWeekForDate(
    new Date(
      endOfNextWeek.getTime() - (initChartRange - 1) * 7 * 24 * 60 * 60 * 1000 //subtract 1 week to get the start of the block not the week earlier
    )
  );

  const [selectedRangeEnd, setSelectedChartRangeEnd] =
    useState<Date>(endOfNextWeek);
  const [selectedRangeStart, setSelectedChartRangeStart] = useState<Date>(
    startOfWeek17WeeksAgo
  );
  const [chartRangeWeeks, setChartRangeWeeks] =
    useState<number>(initChartRange);
  const [weeklyTotals, setWeeklyTotals] =
    useState<WeeklyActivityTotalsPerActivityCollection | null>();

  const handleToggle = (value: string) => {
    // The ToggleButton.Row value appeasr to be null if the selected button is selected again?
    // To fix this, only update selectedKey if value is not null
    if (value !== null) {
      setSelectedKey(value);
    }
  };

  // Callback function to handle the selected value
  const handleTotalTypeSelect = (value: TotalTypeKey) => {
    setSelectedTotalType(value);
  };

  const fetchData = async () => {
    try {
      // setChartLoading(true);
      // dispatch({ type: "SET_LOADING" }); // Update the global state
      // check global state.coachView and only fetch activities for either SELF of the selected athlete
      const user_id = getCurrentViewUserId(state);
      const { data } = await fetchActivitiesInDateRange(
        user_id,
        selectedRangeEnd, //before
        selectedRangeStart //after
      );

      if (data) {
        // If data is returned, update the global state
        dispatch({
          type: "EXTEND_ACTIVITIES",
          activities: data,
        });
      }
    } catch (error) {
      // Handle errors here
      console.error("Error fetching activities:", error);
    } finally {
      console.log("fetchData finally");
      // setChartLoading(false);
      // dispatch({ type: "SET_LOADED" }); // Update the global state
    }
  };

  const calculateTotals = async () => {
    try {
      setChartLoading(true);
      console.log("chartLoading should be true", chartLoading);

      if (
        selectedRangeStart < oldestDateFetched ||
        selectedRangeEnd > newestDateFetched
      ) {
        console.log("Dates out of local range. Fetching data...");
        await fetchData();
        console.log("chartLoading should be true", chartLoading);
        // Update the oldest and newest dates
        const { oldestDate, newestDate } = getOldestAndNewestDates(
          state.activities
        );
        // setOldestDateFetched to the oldest of either the current oldestDateFetched or the selectedRangeStart
        setOldestDateFetched(
          oldestDateFetched
            ? selectedRangeStart < oldestDateFetched
              ? selectedRangeStart
              : oldestDateFetched
            : selectedRangeStart
        );
        // setNewestDateFetched to the newest of either the current newestDateFetched or the selectedRangeEnd
        setNewestDateFetched(
          newestDateFetched
            ? selectedRangeEnd > newestDateFetched
              ? selectedRangeEnd
              : newestDateFetched
            : selectedRangeEnd
        );
      }

      // dispatch({ type: "SET_LOADING" }); // Update the global state
      const totals = calculateWeeklyTotalsPerActivityForDateRange(
        state.activities,
        selectedRangeStart,
        selectedRangeEnd
      );
      setWeeklyTotals(totals);
      console.log("calculateTotals", selectedRangeStart, selectedRangeEnd);
      console.log("calculateTotals state.activities", state.activities);
      console.log("calculateTotals totals", totals);
    } catch (error) {
      // Handle errors here
      console.error("Error fetching updating totals:", error);
    } finally {
      // dispatch({ type: "SET_LOADED" }); // Update the global state
      console.log("chartLoading should be true", chartLoading);
      setChartLoading(false);
      console.log("chartLoading should be false", chartLoading);
    }
  };

  useEffect(() => {
    // Call the function to calculate totals when the component mounts
    console.log("useEffect calculateTotals");
    console.log("chartLoading", chartLoading);
    calculateTotals();
  }, [state.activities, selectedRangeStart]);

  const handleBarClick = (data: { date: string }) => {
    setSelectedBar(data);
    setSelectedWeekStart(new Date(data.date));
  };

  const handleChartRangeChange = (rangeStart: Date, rangeEnd: Date) => {
    setSelectedChartRangeStart(rangeStart);
    setSelectedChartRangeEnd(rangeEnd);
    // const weeks = Math.floor(
    //   (rangeEnd.getTime() - rangeStart.getTime()) / (7 * 24 * 60 * 60 * 1000)
    // );
    // setChartRangeWeeks(weeks);
    handleBarClick({ date: formatDateWithClientTimeZone(rangeStart) });
  };

  return (
    <Card style={styles.container}>
      <Card.Title title="Long Term Training Summary" />
      <DateRangeSelector
        dateRangeStart={selectedRangeStart}
        dateRangeEnd={selectedRangeEnd}
        // setDateRangeStart={setSelectedChartRangeStart}
        // setDateRangeEnd={setSelectedChartRangeEnd}
        setDateRange={handleChartRangeChange}
        rangeInWeeks={chartRangeWeeks}
      />

      <Card.Content>
        {chartLoading ? (
          <Loading />
        ) : weeklyTotals && weeklyTotals[selectedKey] ? (
          <View>
            <ActivityTypeToggle
              activityTotals={weeklyTotals}
              selectedType={selectedKey}
              onTypeChange={handleToggle}
            />

            <Text variant="titleMedium" style={styles.summaryTitle}>
              {selectedKey.charAt(0).toUpperCase() + selectedKey.slice(1)}
            </Text>
            <TotalTypeSelect onSelect={handleTotalTypeSelect} />
            <BarChart
              data={convertDailyTotalsObjectToArray(
                weeklyTotals[selectedKey]["weekly"]
              )}
              labelKey="date"
              totalType={selectedTotalType}
              onBarClick={handleBarClick}
            />
            <View style={styles.tableTitle}>
              <WeekDisplay selectedWeekStart={selectedWeekStart} />
            </View>
            <TotalsDataTable
              totals={
                weeklyTotals[selectedKey]["weekly"][
                  formatDateWithClientTimeZone(selectedWeekStart)
                ]
              }
            />
          </View>
        ) : (
          <View style={styles.summaryTitle}>
            <Text>
              No data. Plan some training sessions in the Schedule tab and
              connect Strava for tracking completed activities...
            </Text>
          </View>
        )}
      </Card.Content>
    </Card>
  );
};

const styles = StyleSheet.create({
  container: {
    // flex: 1,
    padding: 16,
  },
  tableTitle: {
    // flex: 1,
    paddingTop: 16,
  },
  summaryTitle: {
    marginTop: 8,
  },
  title: {
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 8,
  },
  description: {
    fontSize: 16,
    marginBottom: 8,
  },
  type: {
    fontSize: 16,
    marginBottom: 8,
  },
  time: {
    fontSize: 16,
    marginBottom: 8,
  },
  startedAt: {
    fontSize: 16,
    marginBottom: 8,
  },
  completed: {
    fontSize: 16,
    marginBottom: 8,
  },
  centeredCell: {
    justifyContent: "center",
    alignItems: "center",
  },
  dateRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  dateText: {
    marginHorizontal: 8, // Adjust the spacing as needed
  },
  toggleButtonRow: {
    // justifyContent: "center",
    // marginTop: 10,
  },

  toggleButton: {
    // width: 100,
  },

  selectedToggleButton: {
    // width: 100,
    // backgroundColor: "blue", // Customize the selected button's background color
  },
});

export default LongTermTrainingSummaryCard;
