import React, { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import { useTheme } from "react-native-paper";
import { MainStackParamList } from "../types/navigation";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text } from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import { useCalendar } from "../provider/CalendarProvider";
import LongTermTrainingSummaryCard from "../components/Insights/LongTermTrainingSummaryCard";
import { calculateWeeklyAndDailyTotalsPerActivity } from "../components/utils/activityUtils";

export default function ({
  navigation,
}: NativeStackScreenProps<MainStackParamList, "MainTabs">) {
  const theme = useTheme();
  const { state, dispatch } = useCalendar();
  const [totals, setTotals] = useState<{} | null>(null); // State to hold the sum of all activities for this week

  useEffect(() => {
    // Sum all activities for this week
    setTotals(calculateWeeklyAndDailyTotalsPerActivity(state.activities));
    console.log(
      "totals",
      calculateWeeklyAndDailyTotalsPerActivity(state.activities)
    );
  }, []);

  return (
    <ScreenWrapper>
      <LongTermTrainingSummaryCard />
    </ScreenWrapper>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
  },
});
