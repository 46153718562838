import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { ToggleButton } from "react-native-paper";
import {
  getEndOfWeekForDate,
  getStartOfNextWeekForDate,
} from "../utils/dateUtils";

interface WeekSelectorProps {
  dateRangeStart: Date;
  dateRangeEnd: Date;
  setDateRange: (rangeStart: Date, rangeEnd: Date) => void;
  rangeInWeeks: number;
}

const DateRangeSelector: React.FC<WeekSelectorProps> = ({
  dateRangeStart,
  dateRangeEnd,
  setDateRange,
  rangeInWeeks,
}) => {
  const getDateRangeStartFromRangeEnd = (dateRangeEnd: Date) => {
    const start = new Date(dateRangeEnd);
    start.setDate(start.getDate() - 7 * rangeInWeeks);
    return new Date(getStartOfNextWeekForDate(start));
  };

  const getDateRangeEndFromRangeStart = (dateRangeStart: Date) => {
    const end = new Date(dateRangeStart);
    end.setDate(end.getDate() + 7 * (rangeInWeeks - 1)); // Subtract 1 to account for the start date
    console.log("dateRangeStart", dateRangeStart);
    console.log("end", end);
    console.log("getEndOfWeekForDate(end)", getEndOfWeekForDate(end));
    return new Date(getEndOfWeekForDate(end));
  };

  const movePreviousRange = () => {
    const previousRangeDateRangeEnd = new Date(dateRangeStart);
    previousRangeDateRangeEnd.setDate(previousRangeDateRangeEnd.getDate() - 7);
    const endOfWeek = getEndOfWeekForDate(previousRangeDateRangeEnd);
    previousRangeDateRangeEnd.setDate(endOfWeek.getDate());
    // setDateRangeEnd(previousRangeDateRangeEnd);
    const previousRangeDateRangeStart = getDateRangeStartFromRangeEnd(
      previousRangeDateRangeEnd
    );
    // setDateRangeStart(previousRangeDateRangeStart);
    setDateRange(previousRangeDateRangeStart, previousRangeDateRangeEnd);
  };

  const moveNextRange = () => {
    const startOfNextWeek = new Date(getStartOfNextWeekForDate(dateRangeEnd));
    // setDateRangeStart(startOfNextWeek);
    // setDateRangeEnd(getDateRangeEndFromRangeStart(startOfNextWeek));
    setDateRange(
      startOfNextWeek,
      getDateRangeEndFromRangeStart(startOfNextWeek)
    );
  };

  // const dateRangeStart = getDateRangeStartFromRangeEnd(new Date(dateRangeEnd));÷
  const formattedDateRangeStart = dateRangeStart.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });
  const formattedDateRangeEndString = dateRangeEnd.toLocaleDateString("en-GB", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return (
    <View style={styles.dateRow}>
      <ToggleButton
        icon="chevron-left"
        onPress={movePreviousRange}
        value="previous"
      />
      <Text style={styles.dateText}>
        {`${formattedDateRangeStart} - ${formattedDateRangeEndString}`}
      </Text>
      <ToggleButton icon="chevron-right" onPress={moveNextRange} value="next" />
    </View>
  );
};

const styles = StyleSheet.create({
  dateRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  dateText: {
    marginHorizontal: 8, // Adjust the spacing as needed
  },
});

export default DateRangeSelector;
