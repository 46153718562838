import React, { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { Avatar, Divider, List } from "react-native-paper";
import ScreenWrapper from "../components/ScreenWrapper";
import Loading from "./utils/Loading";
import WorkoutListItem from "../components/WorkoutLibrary/WorkoutListItem";
import WorkoutCategoryListItem from "../components/WorkoutLibrary/WorkoutCategoryListItem";
import WorkoutLibraryFabGroup from "../components/WorkoutLibrary/WorkoutLibraryFabGroup";
import CreateWorkoutCategoryDialog from "../components/WorkoutLibrary/CreateWorkoutCategoryDialog";
import { useNavigation, useRoute } from "@react-navigation/native";
import {
  LibraryWorkoutData,
  WorkoutLibraryData,
} from "../types/libraryWorkout";
import { fetchWorkoutLibrary } from "../api/workouts";
import { useCalendar } from "../provider/CalendarProvider";
import { CalendarActionTypes } from "../provider/CalendarProvider/types";

const WorkoutLibrary = () => {
  const { state, dispatch } = useCalendar();
  const navigation = useNavigation();
  const route = useRoute();

  const [workoutLibrary, setWorkoutLibrary] = useState([]);
  const [isCreateCategoryDialogVisible, setIsCreateCategoryDialogVisible] =
    useState<bool>(false);
  const [loading, setLoading] = useState<bool>(true);

  const handleMenuItemPress = (screenName: string) => {
    console.log(`Navigating to ${screenName}`);
  };

  const fetchAndSetWorkoutLibrary = async () => {
    try {
      const data = await fetchWorkoutLibrary();
      setWorkoutLibrary(data);
    } catch (error) {
      console.error("Failed to fetch workout library:", error);
    }
  };

  useEffect(() => {
    if (state.fetchWorkoutLibraryRequired) {
      fetchAndSetWorkoutLibrary();
      setLoading(false);
      // set state to fetch activities again
      dispatch({
        type: CalendarActionTypes.FETCH_WORKOUT_LIBRARY_SUCCESS,
      });
    }
  }, [state.fetchWorkoutLibraryRequired]);

  useEffect(() => {
    fetchAndSetWorkoutLibrary();
    setLoading(false);
    // set state to fetch activities again
    dispatch({
      type: CalendarActionTypes.FETCH_WORKOUT_LIBRARY_SUCCESS,
    });
  }, []);

  if (loading) {
    return <Loading />;
  }

  const setStateFetchRequired = () => {
    dispatch({
      type: CalendarActionTypes.FETCH_WORKOUT_LIBRARY,
    });
  };

  return (
    <View style={{ flex: 1 }}>
      <List.Section>
        <List.AccordionGroup>
          {workoutLibrary
            .filter((category) => category.type === "category")
            .map((category) => (
              <WorkoutCategoryListItem
                key={category.id}
                id={category.id}
                title={category.title}
                items={category.items}
                navigation={navigation}
              />
            ))}
        </List.AccordionGroup>
        {workoutLibrary
          .filter((item) => item.type !== "category")
          .map((item) => (
            <WorkoutListItem
              key={item.id}
              id={item.id}
              item={item}
              navigation={navigation}
              title={item.title}
              description={item.description}
              activityTypeEnum={item.type}
            />
          ))}
      </List.Section>
      <WorkoutLibraryFabGroup
        navigation={navigation}
        setIsCreateCategoryDialogVisible={setIsCreateCategoryDialogVisible}
      />
      <CreateWorkoutCategoryDialog
        visible={isCreateCategoryDialogVisible}
        hideDialog={() => setIsCreateCategoryDialogVisible(false)}
        setFetchRequired={() =>
          dispatch({ type: CalendarActionTypes.FETCH_WORKOUT_LIBRARY })
        }
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    padding: 16,
    justifyContent: "space-between", // This will push the content to the top and bottom
  },
});

export default WorkoutLibrary;
