import React, { useCallback, useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { Avatar, Divider, List } from "react-native-paper";
import { ActivityTypeValue } from "../../types/activity";
import { getTypeIcon } from "../utils/activityUtils";
import { useTheme } from "../../provider/ThemeProvider";
import ScheduleWorkoutDialog from "./ScheduleWorkoutDialog";
import { LibraryWorkoutData } from "../../types/libraryWorkout";

interface ItemProps {
  item: LibraryWorkoutData;
  navigation: any;
  title: string;
  description: string | null;
  activityTypeEnum: ActivityTypeValue;
}

const WorkoutListItem = (props: ItemProps) => {
  const { item, navigation, title, description, activityTypeEnum } = props;
  const { theme } = useTheme();
  const [isDialogVisible, setIsDialogVisible] = useState<boolean>(false);

  const handleMenuItemPress = () => {
    navigation.navigate("Library Workout", { item });
  };

  const handleRightIconPress = () => {
    setIsDialogVisible(true);
  };

  return (
    <View style={styles.itemContainer}>
      <TouchableOpacity onPress={handleMenuItemPress} style={styles.item}>
        <List.Icon
          icon={getTypeIcon(activityTypeEnum)}
          style={styles.itemIcon}
        />
        <List.Item
          title={title}
          description={description}
          style={styles.listItem}
        />
      </TouchableOpacity>
      <TouchableOpacity onPress={handleRightIconPress} style={styles.rightIcon}>
        <List.Icon icon="calendar-plus" color={theme.colors.primary} />
      </TouchableOpacity>
      <ScheduleWorkoutDialog
        visible={isDialogVisible}
        hideDialog={() => setIsDialogVisible(false)}
        item={item}
      />
    </View>
  );
};

export default WorkoutListItem;

// const styles = StyleSheet.create({
//   item: {
//     paddingVertical: 4,
//     paddingRight: 10,
//     flexDirection: "row",
//   },
// });

const styles = StyleSheet.create({
  itemContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingRight: 24,
    paddingVertical: 8,
  },
  itemIcon: {
    marginLeft: 16,
  },
  item: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  listItem: {
    flex: 1,
  },
  rightIcon: {
    paddingLeft: 8,
  },
});
