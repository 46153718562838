import { DaysOfWeek, DateRange } from "../../types/calendar";

export function formatDateToCustomFormat(isoDate: string | number | Date) {
  const date = new Date(isoDate);

  const options = {
    weekday: "long" as const,
    day: "numeric" as const,
    month: "short" as const,
  };
  const formattedDate = date.toLocaleDateString("en-AU", options);

  return formattedDate;
}

// Example usage
// const inputDate: string = "2023-09-06T14:00:00+00:00";
// const formattedDate: string = formatDateWithClientTimeZone(inputDate);
// console.log(formattedDate); // Output: "2023-09-06"
export function formatDateWithClientTimeZone(inputDate: string | Date): string {
  // Parse the input date string into a Date object
  const parsedDate =
    typeof inputDate === "string" ? new Date(inputDate) : inputDate;
  const yyyy = parsedDate.getFullYear();
  let mm = parsedDate.getMonth() + 1; // month is zero-based
  let dd = parsedDate.getDate();
  const ddString = dd > 9 ? dd : "0" + dd;
  const mmString = mm > 9 ? mm : "0" + mm;

  const formattedDate: string = yyyy + "-" + mmString + "-" + ddString;
  return formattedDate;
}

export function formatFriendlyDate(
  isoString: string | Date | null,
  includeWeekday: boolean = true
): string {
  const currentDate = new Date();
  const inputDate = isoString ? new Date(isoString) : new Date();

  // Check if the input date is today
  if (
    inputDate.getDate() === currentDate.getDate() &&
    inputDate.getMonth() === currentDate.getMonth() &&
    inputDate.getFullYear() === currentDate.getFullYear()
  ) {
    return "today";
  }

  // Calculate yesterday's date
  const yesterday = new Date(currentDate);
  yesterday.setDate(currentDate.getDate() - 1);

  // Check if the input date is tomorrow
  if (
    inputDate.getDate() === yesterday.getDate() &&
    inputDate.getMonth() === yesterday.getMonth() &&
    inputDate.getFullYear() === yesterday.getFullYear()
  ) {
    return "yesterday";
  }

  // Calculate tomorrow's date
  const tomorrow = new Date(currentDate);
  tomorrow.setDate(currentDate.getDate() + 1);

  // Check if the input date is tomorrow
  if (
    inputDate.getDate() === tomorrow.getDate() &&
    inputDate.getMonth() === tomorrow.getMonth() &&
    inputDate.getFullYear() === tomorrow.getFullYear()
  ) {
    return "tomorrow";
  }

  if (includeWeekday) {
    // Format the input date as "Thursday, 13 Sep"
    const options = {
      weekday: "long" as const,
      day: "numeric" as const,
      month: "short" as const,
    };
    return inputDate.toLocaleDateString("en-AU", options);
  } else {
    // Format the input date as "Thursday, 13 Sep"
    const options = {
      day: "numeric" as const,
      month: "short" as const,
      year: "numeric" as const,
    };
    return inputDate.toLocaleDateString("en-AU", options);
  }
}

// Format the input date as day of week in format "SU", "M", "TU", "W", "TH", "F", "SA"
export function dateToWeekdayAbbreviation(dateString: string) {
  const daysOfWeek = ["SU", "M", "TU", "W", "TH", "F", "SA"];
  const date = new Date(dateString);
  const dayOfWeekIndex = date.getDay();

  if (dayOfWeekIndex >= 0 && dayOfWeekIndex < daysOfWeek.length) {
    return daysOfWeek[dayOfWeekIndex];
  } else {
    return "Invalid Date";
  }
}

/**
 * Returns the start date of the week for a given date.
 * The start of the week is considered to be Monday.
 *
 * @param {string | Date} date - The date string in ISO format (YYYY-MM-DD).
 * @returns {string} - The start date of the week in ISO format (YYYY-MM-DD).
 *
 * @example
 // Returns '2023-10-02' for input '2023-10-05'
 * getStartOfWeekForDate('2023-10-05');
 */
export function getStartOfWeekForDate(date: string | Date) {
  const d = typeof date === "string" ? new Date(date) : date;
  const dayOfWeek = d.getDay();
  const startOfWeek = new Date(d);
  startOfWeek.setDate(d.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1));
  startOfWeek.setHours(0, 0, 0, 0);
  return startOfWeek;
}

/**
 * Returns an array of start of week date strings in format '2024-11-04' for a given startDate and endDate.
 * The start of the week is considered to be Monday.
 **/
export function getStartOfWeeksForDateRange(
  startDate: string | Date,
  endDate: string | Date
) {
  const start = getStartOfWeekForDate(startDate);
  const end = getStartOfWeekForDate(endDate);

  const startOfWeeks = [];
  let current = new Date(start);
  while (current <= end) {
    startOfWeeks.push(formatDateWithClientTimeZone(current));
    current.setDate(current.getDate() + 7);
  }

  return startOfWeeks;
}

/**
 * Returns the end date of the week for a given date.
 * The end of the week is considered to be Sunday.
 *
 * @param {string | Date} date - The date string in ISO format (YYYY-MM-DD).
 * @returns {string} - The end date of the week in ISO format (YYYY-MM-DD).
 *
 * @example
 // Returns '2023-10-08' for input '2023-10-05'
 * getEndOfWeekForDate('2023-10-05');
 */
export function getEndOfWeekForDate(date: string | Date) {
  const d = typeof date === "string" ? new Date(date) : date;
  const dayOfWeek = d.getDay();
  const endOfWeek = new Date(d);
  endOfWeek.setDate(d.getDate() + 7 - dayOfWeek);
  endOfWeek.setHours(0, 0, 0, 0);
  return endOfWeek;
}

/**
 * Returns the start date of next week for a given date.
 * The start of the week is considered to be Monday.
 *
 * @param {string | Date} date - The date string in ISO format (YYYY-MM-DD).
 * @returns {string} - The start date of the next week in ISO format (YYYY-MM-DD).
 *
 * @example
 * // Returns '2023-10-09' for input '2023-10-05'
 * getStartOfNextWeekForDate('2023-10-05');
 */
export function getStartOfNextWeekForDate(date: string | Date): string {
  const d = typeof date === "string" ? new Date(date) : date;
  const startOfNextWeek = getStartOfWeekForDate(d);
  startOfNextWeek.setDate(startOfNextWeek.getDate() + 7);
  startOfNextWeek.setHours(0, 0, 0, 0);
  // convert to string
  return formatDateWithClientTimeZone(startOfNextWeek);
}

/**
 * Converts a Date object to an ISO string (YYYY-MM-DD).
 *
 * @param {Date} date - The Date object to be converted.
 * @returns {string} - The ISO string representation of the date (YYYY-MM-DD).
 *
 * @example
 // Returns '2023-10-05' for a Date object representing October 5, 2023
 * getDateISOString(new Date(2023, 9, 5));
 */
export function getDateISOString(date: Date): string {
  return date.toISOString().split("T")[0];
}

export function isThisWeek(inputDate: Date): boolean {
  const currentDate = new Date();
  const currentWeekStart = getStartOfWeekForDate(currentDate);
  const currentWeekEnd = new Date(currentWeekStart);
  currentWeekEnd.setDate(currentWeekStart.getDate() + 7);

  return inputDate >= currentWeekStart && inputDate < currentWeekEnd;
}

export function getLocalStartOfDayForDate(date: Date | string): string {
  const utcOffset = new Date().getTimezoneOffset() * -60;
  const localStartOfDay = new Date(date);
  localStartOfDay.setHours(0, 0, 0, 0);
  localStartOfDay.setSeconds(localStartOfDay.getSeconds() + utcOffset);
  return localStartOfDay.toISOString().slice(0, -1);
}

export function isSameDayLocal(date1: Date, date2: Date): boolean {
  const localDate1 = getLocalStartOfDayForDate(date1);
  const localDate2 = getLocalStartOfDayForDate(date2);
  return localDate1 === localDate2;
}

export function formatTimeOfDay(date: Date | string | null): string {
  const localStartOfDay = date ? new Date(date) : new Date();
  const hours = localStartOfDay.getHours();
  const minutes = localStartOfDay.getMinutes();
  const timeOfDay = `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
  return timeOfDay;
}

export const isFirstWeekOfMonth = (date: string): boolean => {
  const d = new Date(date);
  return d.getDate() <= 7;
};

// function to get day of week local time enum DaysOfWeek
// export enum DaysOfWeek {
//   Monday = "monday",
//   Tuesday = "tuesday",
//   Wednesday = "wednesday",
//   Thursday = "thursday",
//   Friday = "friday",
//   Saturday = "saturday",
//   Sunday = "sunday",
// }
export const getDayOfWeekTodayLocal = (): DaysOfWeek => {
  const d = new Date();
  const day = d.getDay();
  switch (day) {
    case 0:
      return DaysOfWeek.Sunday;
    case 1:
      return DaysOfWeek.Monday;
    case 2:
      return DaysOfWeek.Tuesday;
    case 3:
      return DaysOfWeek.Wednesday;
    case 4:
      return DaysOfWeek.Thursday;
    case 5:
      return DaysOfWeek.Friday;
    case 6:
      return DaysOfWeek.Saturday;
    default:
      return DaysOfWeek.Monday;
  }
};

// function to get day of week tomorrow local time enum DaysOfWeek
export const getDayOfWeekTomorrowLocal = (): DaysOfWeek => {
  const d = new Date();
  d.setDate(d.getDate() + 1);
  const day = d.getDay();
  switch (day) {
    case 0:
      return DaysOfWeek.Sunday;
    case 1:
      return DaysOfWeek.Monday;
    case 2:
      return DaysOfWeek.Tuesday;
    case 3:
      return DaysOfWeek.Wednesday;
    case 4:
      return DaysOfWeek.Thursday;
    case 5:
      return DaysOfWeek.Friday;
    case 6:
      return DaysOfWeek.Saturday;
    default:
      return DaysOfWeek.Monday;
  }
};

export const getDayOfWeek = (date: Date): DaysOfWeek => {
  const day = date.getDay();
  switch (day) {
    case 0:
      return DaysOfWeek.Sunday;
    case 1:
      return DaysOfWeek.Monday;
    case 2:
      return DaysOfWeek.Tuesday;
    case 3:
      return DaysOfWeek.Wednesday;
    case 4:
      return DaysOfWeek.Thursday;
    case 5:
      return DaysOfWeek.Friday;
    case 6:
      return DaysOfWeek.Saturday;
    default:
      throw new Error("Invalid day");
  }
};

export const getDatesInRange = (
  range: DateRange,
  selectedDays: DaysOfWeek[]
): Date[] => {
  const { startDate, endDate } = range;
  const dates: Date[] = [];
  // handle undefined startDate or endDate
  if (!startDate || !endDate) {
    return dates;
  }
  let currentDate = new Date(startDate);

  while (currentDate <= endDate) {
    if (selectedDays.includes(getDayOfWeek(currentDate))) {
      dates.push(new Date(currentDate));
    }
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

// function to return the date x weeks from now
export const getDateWeeksFromNow = (weeks: number): Date => {
  const d = new Date();
  d.setDate(d.getDate() + weeks * 7);
  return d;
};
