import React from "react";
import { View, Text } from "react-native";
import { Button, List } from "react-native-paper";
import { DatePickerModal } from "react-native-paper-dates";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { DateRange } from "../../types/calendar";

interface DateRangePickerInputProps {
  props: any;
  setDateRange: (range: DateRange) => void;
  validRange?: DateRange;
}

const DateRangePickerInput = ({
  props,
  setDateRange,
  validRange,
}: DateRangePickerInputProps) => {
  const [range, setRange] = React.useState<DateRange>({
    startDate: undefined,
    endDate: undefined,
  });
  const [open, setOpen] = React.useState(false);

  const onDismiss = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirm = (dateRange: DateRange) => {
    setOpen(false);
    setRange(dateRange);
    setDateRange(dateRange);
  };

  const formatDate = (date: Date | undefined) => {
    if (!date) return "";
    const options = {
      year: "numeric" as const,
      month: "short" as const,
      day: "numeric" as const,
    };
    return new Date(date).toLocaleDateString("en-GB", options);
  };

  return (
    <SafeAreaProvider>
      <View>
        <List.Item
          title={
            range.startDate && range.endDate
              ? `Starts ${formatDate(range.startDate)} until ${formatDate(
                  range.endDate
                )}`
              : "Select date range"
          }
          titleNumberOfLines={2}
          onPress={() => {
            setOpen(true);
          }}
          left={(props) => <List.Icon {...props} icon="calendar-range" />}
          right={(props) => <List.Icon {...props} icon="chevron-right" />}
        />
        <DatePickerModal
          {...props}
          locale="en-GB"
          mode="range"
          label="Select Range"
          visible={open}
          onDismiss={onDismiss}
          onConfirm={onConfirm}
          startDate={range.startDate}
          endDate={range.endDate}
          {...(validRange ? { validRange } : {})}
        />
      </View>
    </SafeAreaProvider>
  );
};

export default DateRangePickerInput;
