import React from "react";
import { View, StyleSheet } from "react-native";
import { ToggleButton } from "react-native-paper";
import { ActivityTotalsPerActivity } from "../../types/activity";
import { types as activityTypes } from "../../constants/activityTypes";

interface ActivityTypeToggleProps {
  activityTotals: ActivityTotalsPerActivity;
  selectedType: string;
  onTypeChange: (type: string) => void;
}

const ActivityTypeToggle: React.FC<ActivityTypeToggleProps> = ({
  activityTotals,
  selectedType,
  onTypeChange,
}) => {
  console.log();
  return (
    <ToggleButton.Row style={styles.toggleContainer}>
      {Object.keys(activityTotals).map((key, index) => (
        <ToggleButton
          key={index}
          icon={
            key === "total"
              ? "chart-bar-stacked"
              : activityTypes.find((type) => type.label === key)?.icon ||
                "heart"
          }
          value={key}
          status={selectedType === key ? "checked" : "unchecked"}
          onPress={() => onTypeChange(key)}
        />
      ))}
    </ToggleButton.Row>
  );
};

const styles = StyleSheet.create({
  toggleContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "center",
    marginVertical: 10,
  },
});

export default ActivityTypeToggle;
