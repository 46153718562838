import { formatSchedule } from "./formatSchedule";
import { CalendarState, CalendarAction, CalendarActionTypes } from "./types";
import { Database } from "../../types/supabase";
import {
  UserProfile,
  CoachedAthleteProfile,
  CoachProfile,
} from "../../types/user";

const updateActivitiesWithMatchingIds = (
  existingActivities: any[],
  newActivities: any[]
) => {
  return existingActivities.map((existingActivity) => {
    const matchingActivity = newActivities.find(
      (newActivity) => newActivity.id === existingActivity.id
    );
    return matchingActivity || existingActivity;
  });
};

const mergeActivities = (existingActivities, newActivities) => {
  const activityMap = new Map();

  // Add existing activities to the map
  existingActivities.forEach((activity) => {
    activityMap.set(activity.id, activity);
  });

  // Add new activities to the map, replacing any existing ones with the same ID
  newActivities.forEach((activity) => {
    activityMap.set(activity.id, activity);
  });

  // Convert the map back to an array
  return Array.from(activityMap.values());
};

const calendarReducer = (
  state: CalendarState,
  action: CalendarAction
): CalendarState => {
  switch (action.type) {
    case CalendarActionTypes.TOGGLE_CALENDAR:
      return { ...state, showCalendar: !state.showCalendar };
    case "SET_ACTIVITIES":
      return {
        ...state,
        activities: updateActivitiesWithMatchingIds(
          state.activities,
          action.activities
        ),
        schedule: formatSchedule(
          updateActivitiesWithMatchingIds(state.activities, action.activities)
        ),
      }; // Update activities in the state
    case "SET_SCHEDULE":
      return {
        ...state,
        schedule: formatSchedule(action.activities),
        activities: action.activities,
      }; // Update schedule in the state
    case "SET_PROFILE":
      return {
        ...state,
        profile: action.profile,
      }; // Update profile in the state
    case "SET_LOADING":
      return { ...state, loading: true };
    case "SET_LOADED":
      return { ...state, loading: false };
    case "SET_FOCUS_DATE":
      return { ...state, focusDate: action.date };
    case "FETCH_ACTIVITIES":
      return { ...state, fetchRequired: true };
    case "FETCH_ACTIVITIES_COMPLETE":
      return { ...state, fetchRequired: false };
    case CalendarActionTypes.EXTEND_ACTIVITIES:
      const mergedActivities = mergeActivities(
        state.activities,
        action.activities
      );
      return {
        ...state,
        activities: mergedActivities,
        schedule: formatSchedule(mergedActivities),
      };
    case "FETCH_PROFILE":
      return { ...state, fetchProfileRequired: true };
    case "FETCH_PROFILE_COMPLETE":
      return { ...state, fetchProfileRequired: false };
    case CalendarActionTypes.FETCH_ATHLETES:
      return { ...state, loading: true, fetchAthletesRequired: true }; // Start loading when fetching starts
    case CalendarActionTypes.FETCH_ATHLETES_SUCCESS:
      return {
        ...state,
        loading: false, // Stop loading when fetching is successful
        fetchAthletesRequired: false,
        athletes: action.athletes, // Update athletes state with fetched data
      };
    case CalendarActionTypes.FETCH_ATHLETES_FAILURE:
      return {
        ...state,
        loading: false, // Stop loading when fetching fails
        fetchAthletesRequired: true,
        error: action.error, // Store the error message in state
      };
    case CalendarActionTypes.FETCH_COACHES:
      return { ...state, loading: true, fetchCoachesRequired: true }; // Start loading when fetching starts
    case CalendarActionTypes.FETCH_COACHES_SUCCESS:
      return {
        ...state,
        loading: false, // Stop loading when fetching is successful
        fetchCoachesRequired: false,
        coaches: action.coaches, // Update coaches state with fetched data
      };
    case CalendarActionTypes.FETCH_COACHES_FAILURE:
      return {
        ...state,
        loading: false, // Stop loading when fetching fails
        fetchCoachesRequired: true,
        error: action.error, // Store the error message in state
      };
    case CalendarActionTypes.SET_COACH_VIEW:
      return { ...state, coachView: action.payload };
    // workout library
    case CalendarActionTypes.FETCH_WORKOUT_LIBRARY:
      return {
        ...state,
        fetchWorkoutLibraryRequired: true,
        loading: true,
      };
    case CalendarActionTypes.FETCH_WORKOUT_LIBRARY_SUCCESS:
      return {
        ...state,
        fetchWorkoutLibraryRequired: false,
        loading: false,
        workoutLibrary: action.workoutLibrary,
      };
    case CalendarActionTypes.FETCH_WORKOUT_LIBRARY_FAILURE:
      return {
        ...state,
        fetchWorkoutLibraryRequired: true,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default calendarReducer;
