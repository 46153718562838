import React, { useCallback } from "react";
import { StyleSheet } from "react-native";
import { List, Text } from "react-native-paper";
import { ActivityTypeValue } from "../../types/activity";
import WorkoutListItem from "./WorkoutListItem";

interface ItemProps {
  id: string;
  title: string;
  items: any[];
}

const WorkoutCategoryAccordionListItem = (props: ItemProps) => {
  const { id, title, items, navigation } = props;

  return (
    <List.Accordion
      title={title}
      id={id}
      left={(props) => <List.Icon {...props} icon="folder" />}
    >
      {items.length > 0 ? (
        items.map((item, index) => (
          <WorkoutListItem
            key={item.id}
            id={item.id}
            item={item}
            navigation={navigation}
            title={item.title}
            description={item.description}
            activityTypeEnum={item.type}
          />
        ))
      ) : (
        <Text>None.</Text>
      )}
    </List.Accordion>
  );
};

export default WorkoutCategoryAccordionListItem;

const styles = StyleSheet.create({
  item: {
    paddingVertical: 4,
    paddingRight: 10,
    flexDirection: "row",
  },
});
